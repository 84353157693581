import moment from 'moment';
import React from 'react';
import { IUser } from 'types/users';
import styles from './styles.module.scss';
import { ISession } from 'types/session';

const date = new Date();

const SessionTable = ({data, curDateTime}:{data: ISession; curDateTime?: Date} ) => {
  return (
    <table className={styles.table}>
      <tbody>
        <tr>
          <td>ФИО: {data?.outsourcing?.user?.firstname} {data?.user?.secondname} {data?.user?.middlename}</td>
        </tr>
        <tr>
          <td>Должность: {data?.outsourcing?.pos ? data?.outsourcing.pos?.title : data?.outsourcing?.position}</td>
        </tr>
        <tr>
          <td>Компания: {data?.outsourcing?.counterparty?.title}</td>
        </tr>
        <tr>
          <td>Плановые дата и время прихода: {moment(data?.request?.start).format('DD.MM.YY HH:mm')}</td>
        </tr>
        <tr>
          <td>Плановые дата и время ухода: {moment(data?.request?.end).format('DD.MM.YY HH:mm')}</td>
        </tr>
        {curDateTime && (
        <tr>
          <td>{moment(curDateTime).format('DD.MM.YY HH:mm')}</td>
        </tr>
        )}
      </tbody>
    </table>
  )
};

export default SessionTable;