// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popup--QAYdd{display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.4);z-index:1000}.popup-content--Grivd{background-color:#fefefe;padding:20px;border:1px solid #888;width:80%;max-width:500px;position:relative}.close--eXNtv{position:absolute;top:10px;right:15px;font-size:28px;font-weight:bold;cursor:pointer;transition:color .3s}.close--eXNtv:hover{color:#000}", "",{"version":3,"sources":["webpack://./src/components/Popup/styles.module.scss"],"names":[],"mappings":"AACA,cACI,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,+BAAA,CACA,YAAA,CAGF,sBACE,wBAAA,CACA,YAAA,CACA,qBAAA,CACA,SAAA,CACA,eAAA,CACA,iBAAA,CAGF,cACE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,cAAA,CACA,gBAAA,CACA,cAAA,CACA,oBAAA,CAEA,oBACE,UAAA","sourcesContent":["// src/Popup.module.scss\n.popup {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.4);\n    z-index: 1000;\n  }\n  \n  .popup-content {\n    background-color: #fefefe;\n    padding: 20px;\n    border: 1px solid #888;\n    width: 80%;\n    max-width: 500px;\n    position: relative;\n  }\n  \n  .close {\n    position: absolute;\n    top: 10px;\n    right: 15px;\n    font-size: 28px;\n    font-weight: bold;\n    cursor: pointer;\n    transition: color 0.3s;\n  \n    &:hover {\n      color: black;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "popup--QAYdd",
	"popup-content": "popup-content--Grivd",
	"close": "close--eXNtv"
};
export default ___CSS_LOADER_EXPORT___;
